/* .fit {
  min-height: calc(100vh - 4.1875rem);

  @screen md {
    min-height: calc(100vh - 32.1875rem);
  }
} */

.rich-text {
  font-size: 0.75rem;
}

.page_content > .rich-text {
  @apply w-full;
}

.rich-text .h1 {
  @apply mt-2 border-solid border-b pb-5 text-primaryA text-base font-medium uppercase;
  border-color: var(--grey300);
}

.rich-text .h2 {
  @apply mt-4 p-3 text-primaryA text-sm font-medium uppercase;
  font-size: 0.75rem;
}

@screen md {
  .rich-text .h1 {
    @apply text-sm;
  }
  .rich-text .h2 {
    @apply p-0 text-xs;
  }
}

.rich-text .h1 + p {
  margin-top: 1.125rem;
}

.rich-text li:before {
  @apply inline-block w-1 h-1 bg-primaryA mr-7;
  content: '';
  border-radius: 100%;
}
[dir='rtl'] .rich-text li:before {
  @apply ml-7;
  margin-right: unset;
}

.title,
p b {
  @apply block pt-3 pb-0.5;
}
.title strong,
p b {
  @apply uppercase;
  font-size: 0.875rem;
}

.rich-text a {
  text-decoration: underline;
}
.rich-text a:hover {
  color: #000;
}

.rich-text .s-table th {
  @apply text-xxs border-b-2 border-solid uppercase;
  color: var(--grey600);
  padding: 0.625rem 0.3125rem;
  @screen md {
    padding: 0.875rem 0;
  }
}

.rich-text .s-table td {
  @apply border-b border-solid text-grey600;
  padding: 0.875rem 0;
  @screen md {
    color: var(--grey500);
  }
}

.rich-text .s-table th,
.rich-text .s-table td {
  @apply text-center md:text-sm;
  font-size: 0.75rem;
}

.rich-text .cms-main-section {
  @apply text-xs font-medium uppercase text-center  pb-0.5 md:pb-2.5;
  font-size: 0.75rem;
}

.rich-text .cms-home-title-s-chronicle {
  @apply pb-2.5 font-gilda text-2xl text-center font-normal;
  font-size: 1.5625rem;
  line-height: 1.8125rem;
  @screen md {
    @apply text-4xl leading-9;
  }
}

.rich-text .cms-main-description {
  @apply pb-5 text-center font-lora font-normal;
  max-width: 38.75rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  margin: 0 auto;
}

.rich-text .cms-home-border-link {
  @apply w-full mx-auto p-2.5 font-inter text-xs text-primaryA text-center font-medium bg-primaryB border-2 border-primaryA uppercase;
  max-width: 15.25rem;
  transition: background-color 0.3s;
}

.rich-text .cms-home-border-link:hover {
  @apply text-primaryB bg-primaryA;
}

.rich-text .cms-home-title-xs {
  @apply mb-2.5 text-center text-xs font-inter font-medium text-primaryA uppercase;
}

.rich-text .cms-home-title-xs-chronicle {
  @apply mb-2.5 font-gilda text-center font-normal;
  font-size: 1.5625rem;
  line-height: 1.8125rem;
}

.rich-text .cms-home-title-xl-chronicle {
  @apply pb-2.5 text-4xl font-gilda text-center font-normal;
  @screen md {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
}

.rich-text .cms-home-title-xs-left {
  @apply text-xs font-inter font-normal;
}

.rich-text .cms-home-description {
  @apply pb-5 text-center text-sm font-inter font-normal md:block;
}

/* Custom block style */
.home_large_block .cms-home-description,
.home_medium_block .cms-home-description,
.home_small_block .cms-home-description {
  @apply hidden md:block;
}
.home_large_block .cms-home-border-link,
.home_medium_block .cms-home-border-link,
.home_small_block .cms-home-border-link {
  @apply hidden md:block;
}

.header_page .cms-home-description {
  @apply font-lora;
}
.header_page .cms-main-description {
  @apply typo-lora-text-m;
}

.slide_block .rich-text .cms-home-title-s-chronicle {
  @apply pb-1.5;
}

.guide_block .rich-text .cms-home-description {
  @apply text-justify;

  @screen md {
    @apply mb-5;
    padding: 0 14%;
  }
}

.description_listing_header {
  @apply mb-0 !important;
}

/* Size Guide */
#size_guide .rich-text {
  max-width: calc(100vw - 2rem);
  @screen md {
    @apply w-full;
  }
}

#size_guide .rich-text .s-table {
  @apply block overflow-x-auto;

  @screen md {
    @apply table overflow-hidden;
  }
}

#size_guide .rich-text .h1 {
  @apply text-left border-solid border-b font-medium leading-4 text-base uppercase;
}

#size_guide .rich-text .s-table th {
  @apply border border-solid;
  min-width: 3rem;

  @screen md {
    @apply border-t-0 border-r-0 border-l-0 border-b-2 min-w-0 text-xxs;
  }
}

#size_guide .rich-text .s-table td {
  @apply border border-solid;
  min-width: 3rem;

  @screen md {
    @apply border-t-0 border-r-0 border-l-0 min-w-0;
  }
}

#size_guide .rich-text .s-table th:first-child,
#size_guide .rich-text .s-table td:first-child {
  @apply text-left pl-2 font-medium;
  min-width: 9.25rem;
}

/* focus-visible */
.js-focus-visible :not(.focus-visible):focus {
  @apply outline-none;
}
.js-focus-visible .focus-visible:focus {
  @apply ring outline-none;
}

.adyen-dropin
  .adyen-checkout__button.adyen-checkout__button--pay
  .adyen-checkout__button__icon {
  @apply relative;
  top: -2px;
}

.adyen-dropin .adyen-checkout__payment-method {
  @apply m-0 border-0 border-b rounded-none !important;
  border-bottom: 1px solid #c5c5c5;
}

.adyen-dropin .adyen-checkout__payment-method:first-child {
  @apply border-t !important;
  border-top: 1px solid #c5c5c5;
}

.adyen-dropin .adyen-checkout__payment-method__image__wrapper {
  @apply mr-2;
}
[dir='rtl'] .adyen-dropin .adyen-checkout__payment-method__image__wrapper {
  @apply ml-2;
  margin-right: unset;
}

.adyen-dropin .adyen-checkout__payment-method--selected {
  @apply bg-primaryB m-0;
}

.adyen-dropin .adyen-checkout__payment-method__details {
  @apply p-0;
}

.adyen-dropin .adyen-checkout__payment-method__details__content {
  @apply relative;
}

.adyen-dropin
  .adyen-checkout__payment-method:not(
    .adyen-checkout__payment-method--selected
  ):hover
  .adyen-checkout__payment-method__image {
  box-shadow: 0 0 0 2px #fff;
}

.adyen-dropin
  .adyen-checkout__payment-method:hover:not(
    .adyen-checkout__payment-method--selected
  )
  .adyen-checkout__payment-method__radio {
  @apply border-primaryA shadow-none;
}

.adyen-dropin .adyen-checkout__payment-method__header {
  @apply px-0 py-2;
}

.adyen-dropin .adyen-checkout__payment-method__header__title:after {
  @apply block;
  content: '';
  display: block;
  width: 3.125rem;
}

.adyen-dropin .adyen-checkout__payment-method__header__title {
  @apply w-full;
}

.adyen-dropin .adyen-checkout__payment-method__name {
  @apply text-center block;
}

.adyen-dropin .adyen-checkout__payment-method__brands {
  @apply hidden;
}

.adyen-dropin .adyen-checkout__button.adyen-checkout__button--pay {
  @apply bg-primaryA text-primaryB cursor-pointer transition ease-in-out duration-150 shadow-sm text-center justify-center uppercase border border-transparent items-center;
  transition: background-color 0.3s;
}

.adyen-dropin
  .adyen-checkout__payment-method:not(
    .adyen-checkout__payment-method--selected
  ):hover {
  @apply bg-grey50;
}

.adyen-dropin .adyen-checkout__button.adyen-checkout__button--pay:hover,
.adyen-dropin .adyen-checkout__button.adyen-checkout__button--pay:active {
  @apply border border-primaryA;
  @screen md {
    background-color: #343333;
  }
}
.adyen-dropin .adyen-checkout__button.adyen-checkout__button--pay[data-active] {
  @apply bg-gray-600;
}

.adyen-dropin
  .adyen-checkout__button.adyen-checkout__button--pay
  .adyen-checkout__button__text {
  @apply text-primaryB;
}

.adyen-dropin .adyen-checkout__payment-method__radio {
  @apply h-2 w-2 rounded-none right-5;
  left: initial;
  background: 0;
  border: 1px solid black;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
}
[dir='rtl'] .adyen-dropin .adyen-checkout__payment-method__radio {
  @apply left-5;
  right: unset;
  transform: rotate(-45deg);
}

.adyen-dropin .adyen-checkout__payment-method__radio:after {
  content: initial;
}

.adyen-dropin
  .adyen-checkout__payment-method__radio.adyen-checkout__payment-method__radio--selected {
  transform: rotate(225deg);
}

.adyen-dropin
  .adyen-checkout__payment-method:hover
  ~ .adyen-dropin
  .adyen-checkout__payment-method__radio:hover {
  border: 1px solid black;
}

.adyen-dropin .adyen-checkout__input,
.adyen-dropin .adyen-checkout__button.adyen-checkout__button--pay {
  @apply rounded-none;
}

.adyen-dropin .adyen-checkout__input,
.adyen-dropin .adyen-checkout__button {
  height: 3rem;
  font-size: 1rem;
}

.adyen-dropin .adyen-checkout__dropdown__button,
.adyen-dropin .adyen-checkout__status {
  @apply rounded-none;
}

.fit > .main-content > .bas-sho,
.fit > .main-content > .pay-sho {
  border-top: none;
}

.fit > .main-content > .bas-sho .basket-frag:first-child {
  margin-top: 0;
}

.pay-sho > .paymentBlock > .-pay-sho {
  margin-top: 0;
}

/* adyen-checkout__payment-method__radio adyen-checkout__payment-method__radio--selected */

/* Checkout V2 dans V3 */
.-bas-sho .title,
.-bas-sho .title strong {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  font-family: inter, sans-serif !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: 600 !important;
}

.-bas-sho .title {
  margin-bottom: 0.25rem !important;
}

.bas-sho .carousel-list .p-new {
  @apply hidden;
}

/* Empty Basket */
.-bas-sho .cms-block a.nu:hover {
  text-decoration: none;
}

@screen xs {
  .emptyBasket p.cms-home-description {
    text-align: center !important;
    color: var(--primary-black, #1f1f1f) !important;
    font-family: Inter, sans-serif !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
  }
}

@screen xs {
  .bas-sho .basket .basket-list .item-info .item-quantity {
    width: 7.5rem !important;
  }
}

.-car-sel li.fake-rad .c-desc {
  padding: 1rem !important;
}
.form-container .form-list {
  padding-bottom: 0px !important;
}

.-pag-sho .modal-content .cms-faq-description {
  font-family: Inter, sans-serif !important;
}
.bas-sho,
.pay-sho {
  border-top: 0 !important;
}

[dir='rtl'] .bas-sho .basket .basket-list .priceBlock {
  right: unset;
  left: 0;
}

[dir='rtl'] .bas-sho .basket .basket-list .item-availability {
  text-align: left;
}

[dir='rtl'] .bas-sho .basket .basket-list .item-price {
  text-align: left;
}
[dir='rtl'] .bas-sho .basket .basket-list .item-price .full-price {
  display: inline-block;
  margin-left: 5px;
}
/* End Checkout V2 dans V3 */
