.formContainer {
  @apply w-full relative mt-5;
}

.formContent {
  @apply w-full  relative mt-2.5;
}

.title {
  @apply text-base font-inter font-medium;
}

.info {
  @apply flex items-center w-full  text-sm font-normal;
  line-height: 1.375rem;
  color: #121212;
}

.formComment button {
  @apply text-grey600 w-full underline whitespace-nowrap;
}

.formAction {
  @apply w-full flex justify-between mx-0 mt-2.5 mb-5;
}

.formAction button {
  @apply h-12 w-1/2 text-sm whitespace-nowrap;

  @screen md {
    @apply text-sm font-medium;
    width: 65%;
  }
}

.formItem:focus {
  @apply border border-solid border-primaryA;
}

.gift {
  border-left: 2px solid #b89336;
  height: 1.375rem;
}

.input {
  @apply flex w-full;
  @screen md {
    width: 90%;
  }
}

.errorInput {
  @apply border border-solid border-negative;
}

.inlineInputs {
  @apply w-full flex flex-col justify-between items-center;
  @screen md {
    @apply flex-row;
  }
}

.checkBox {
  @apply w-full md:w-1/2 flex justify-between;
}

.checkBoxItem {
  width: auto;
}

.checkBox label {
  @apply text-sm font-normal text-primaryA;
}

.selector {
  @apply w-full mr-0;
  @screen md {
    @apply mb-0;
    margin-left: 2%;
  }
}
[dir='rtl'] .selector {
  @screen md {
    margin-left: unset;
    margin-right: 2%;
  }
}

.incrementButton {
  @apply w-full h-12 text-sm text-primaryA font-normal border-none normal-case px-2;
  background-color: #f3f3f3;
  @screen xl {
    width: 9.375rem;
  }
}

.incrementButton:hover {
  @apply text-sm text-primaryA font-normal border-none;
  background-color: #e3e3e3;
}

.disable {
  @apply text-sm text-primaryA font-normal border-none cursor-default;
  background-color: #f3f3f3;
}

.disable:hover {
  background-color: #f3f3f3;
}

.incrementLine {
  @apply flex justify-between mb-10  mt-5;
  @screen md {
    @apply justify-start;
  }
}

.incrementLine .incrementButton:first-child {
  @apply mr-3;
}
[dir='rtl'] .incrementLine .incrementButton:first-child {
  @apply ml-3;
  margin-right: unset;
}

.separator {
  @apply w-full bg-grey300;
  height: 0.0625rem;
}

.radioInput > button {
  @apply w-full mx-0;
  @screen xl {
    width: calc(50% - 0.75rem);
  }
}
