.carouselContainer {
  @apply flex flex-nowrap overflow-hidden;
}

.item {
  @apply flex;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  min-width: 100%;
  width: 100%;
}

.controls {
  @apply flex flex-1 flex-row gap-x-3 justify-center;
}

.controlButton {
  @apply w-1 h-1 rounded-full bg-gray-300 cursor-pointer;
}

.activeControlButton {
  @apply w-1 h-1 rounded-full bg-black cursor-pointer;
}

.controls {
  @apply mb-3.5;
}
