.root {
  @apply relative z-40;
  height: 4.1875rem;

  @screen xl {
    @apply h-auto;
  }
}

.header {
  @apply fixed top-0 right-0 left-0 bg-primaryB z-20;
  transition: all 0.3s;

  @screen xl {
    @apply static h-auto bg-transparent;
  }
}
.header.headerTop {
  @apply relative xl:static;
}

.container {
  @apply flex flex-nowrap items-center justify-center relative py-4 px-2.5;
  padding: 1.125rem 0.625rem 1rem;

  @screen xl {
    @apply pt-5 pb-3 px-11;
  }
}

.headerLeft {
  @apply flex-1 flex flex-row items-center;
}

/* .burgerMenu {
  @apply md:mr-auto;
} */

.customerService {
  @apply hidden xl:block mr-auto font-inter font-medium text-xxs text-primaryA;
  margin-top: 1.313rem;
}

.headerCenter {
  @apply flex flex-col justify-center items-center;
}

.logo {
  @apply h-auto;
  width: 8.75rem;

  @screen xl {
    width: 14.375rem;
  }
}

.customLogo {
  @apply absolute xl:relative;
}

.searchBar {
  @apply mx-3.5;
}

.catchPhrase {
  @apply hidden font-lora text-primaryA text-xxs;

  @screen xl {
    @apply block;
  }
}

.headerRight {
  @apply flex-1 flex flex-row items-center justify-end;

  @screen xl {
    @apply flex-row items-start;
  }
}

.transparent .catchPhrase,
.transparent .customerService,
.transparent a,
.transparent span,
.transparent input[type='text'] {
  @apply text-primaryB;
}

.transparent .headerRight path {
  stroke: #fff;
  stroke-width: 2;
}
.transparent .searchBar path {
  stroke-width: 1;
}
