.formItem {
  @apply flex flex-col relative justify-center 2xl:justify-between items-start py-2.5 px-0;

  @screen md {
    @apply flex-row justify-start items-center w-full;
  }
}
.formLabel {
  @apply absolute mx-1  text-sm bg-primaryB px-2;
  transition: transform 0.3s ease-in-out;
}

.formInput {
  @apply w-full h-12 px-2 font-inter outline-none  border border-grey300 rounded-none;
  font-size: 1.2rem;
  letter-spacing: 0 !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;

  @screen md {
    font-size: 0.875rem;
  }
}

.formInput.isPassword {
  @apply pl-2 pr-10;
}

.formInput.isOptional {
  @apply pl-2 pr-16;
}

.formInput:focus {
  @apply border border-solid border-primaryA;
}

.formInput.errorInput {
  @apply border border-solid border-negative;
}

.formLabel.formUp,
.formInput:focus + .formLabel,
.formInput:-webkit-autofill + .formLabel {
  transform: translate(5%, -140%);
}

.eye {
  @apply absolute top-1/2 right-0 bottom-0 mr-3 flex items-center;
  -webkit-tap-highlight-color: transparent;
  transform: translateY(-50%);
}

[dir='rtl'] .eye {
  @apply ml-3;
  margin-right: unset;
}
.alertMessage {
  @apply mb-2 h-auto;
  font-size: 0.75rem;
  margin-top: -0.5rem;
}

.optional {
  @apply absolute top-1/2 right-0 bottom-0 mr-3 flex items-center;
  font-size: 0.75rem;
  color: #999999;
  transform: translateY(-50%);
}

[dir='rtl'] .optional {
  @apply ml-3;
  margin-right: unset;
}
