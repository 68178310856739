.root {
  @apply fixed bg-primaryB text-primaryA flex items-center inset-0 z-50 justify-center p-0;
  background-color: rgba(0, 0, 0, 0.4);
  @screen md {
    @apply p-3;
  }
}

.modal {
  @apply flex flex-col bg-primaryB border border-grey300 max-h-full h-full overflow-auto w-full opacity-0;
  transition: opacity 0.15s linear;

  @screen md {
    transform: translateY(-25%);
    transition: transform 0.3s ease-out, opacity 0.15s linear;
    width: auto;
  }

  @screen md {
    @apply h-auto;
  }
}

.modal.opened {
  @apply opacity-100;
  transform: translateY(0);
}

.close {
  @apply mr-2 mt-3 md:mt-2 mb-1 hover:text-gray-500 transition ease-in-out duration-150;
}
[dir='rtl'] .close {
  @apply ml-2;
  margin-right: unset;
}
