.col {
  @apply ml-5;

  @screen sm {
    @apply ml-0;
  }
}
[dir='rtl'] .col {
  @apply mr-5;
  margin-left: unset;

  @screen sm {
    @apply mr-0;
  }
}

.imageCol {
  @apply hidden;

  @screen xl {
    @apply block w-1/4;
  }
}

.link {
  @apply block border-b border-solid border-primaryA text-left uppercase text-primaryA;
  padding: 0.9375rem 0 0.5625rem;
}
