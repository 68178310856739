.root {
  @apply relative;
}

.links {
  @apply flex items-center justify-between;
  @screen md {
    @apply flex items-center w-full justify-between;
  }
}

.checked {
  @apply absolute;
  right: -0.45rem;
  top: -0.15rem;
}

.basketIcon {
  @apply block relative md:ml-6;
  height: 1.5rem;

  @screen md {
    @apply ml-0;
  }
}

.basketIconCount {
  @apply flex justify-center items-center w-4 h-4 absolute bg-primaryA text-primaryB font-normal;
  font-size: 0.75rem;
  border-radius: 50%;
  top: -0.15rem;
  left: 65%;
}

.basketIconCountAnimation {
  @apply flex justify-center items-center w-4 h-4 absolute bg-primaryA text-primaryB font-normal;
  font-size: 0.75rem;
  border-radius: 50%;
  top: -0.15rem;
  left: 65%;
  animation: gelatine 0.5s;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

.link {
  @apply relative mx-3.5;
}
.link:last-child {
  @apply mr-4;
}
[dir='rtl'] .link:last-child {
  @apply ml-4;
  margin-right: unset;
}
.linkText {
  @apply mt-2 mb-0.5 text-primaryA text-xs font-medium uppercase;
}
.sublink {
  @apply uppercase;
  color: #777;
}

.linkLogout {
  @apply underline;
}

.telesales {
  @apply hidden lg:flex flex-row px-2.5;
}

.previousAdmin {
  @apply hidden lg:block;
}

.heart {
  @apply flex mr-8;
}
[dir='rtl'] .heart {
  @apply ml-8;
  margin-right: unset;
}
