.separator {
  @apply mx-auto w-full bg-primaryA;
  max-width: 88.75rem;
  height: 0.3125rem;

  @screen lg {
    width: calc(100% - 3.5rem);
  }
}

.separatorBig {
  @apply: w-full;
  height: 0.34rem;
}
