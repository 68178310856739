.root {
  @apply w-full md:w-auto top-0 z-10;
}

.box {
  @apply relative mt-2 bg-primaryB border border-solid border-grey300;
}

.box::after,
.box::before {
  @apply absolute bottom-full border-solid;
  content: '';
}

.center > .box::after,
.center > .box::before {
  left: 49%;
}

.left > .box::after,
.left > .box::before {
  left: 20%;
}

.right > .box::after,
.right > .box::before {
  left: 95%;
}

.box::before {
  margin-left: -0.6875rem;
  border-width: 0.5625rem 0.6875rem;
  border-color: transparent transparent var(--grey300);
}
[dir='rtl'] .box::before {
  margin-right: -0.6875rem;
  margin-left: unset;
}

.box::after {
  margin-left: -0.625rem;
  border-width: 0.5rem 0.625rem;
  border-color: transparent transparent var(--primaryB);
}
[dir='rtl'] .box::after {
  margin-right: -0.625rem;
  margin-left: unset;
}
