.scrollTop {
  @apply hidden;
  @screen md {
    @apply fixed bottom-10 right-5 z-30 cursor-pointer py-3 border border-primaryA bg-primaryB;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}

.showScroll {
  @screen md {
    @apply flex;
  }
}

.arrowThin {
  @apply relative;
  border-left: 0.75rem solid black;
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
}

.arrowThin:after {
  @apply absolute;
  content: '';
  border-left: 0.75rem solid #fff;
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
  top: -0.75rem;
  right: 0.0625rem;
}

.arrowUp {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
