.container {
  @apply flex flex-col lg:flex-row items-center justify-around w-full py-5 lg:py-2.5 px-5 lg:px-0;
}

.title {
  @apply font-gilda;
  font-size: 2rem;
  line-height: 3.125rem;
}

.text {
  @apply text-center font-inter text-sm;
  line-height: 1.5rem;

  @screen lg {
    @apply py-0  text-left;
    max-width: 27rem;
  }
}

.form {
  @apply flex w-full lg:w-auto py-1.5 px-0.5 box-border;
  @screen lg {
    min-width: 25rem;
  }
}

.input {
  @apply flex-1 h-12 px-2.5 text-base lg:text-sm bg-transparent border border-primaryB;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.button {
  @apply flex justify-center items-center w-12 h-12 ml-2.5 text-sm bg-primaryB;
  transition: all 0.3s;
}
[dir='rtl'] .button {
  @apply mr-2.5;
  margin-left: unset;
}
.button:hover {
  @apply opacity-70;
}
.button svg {
  transform: rotate(180deg);
}
