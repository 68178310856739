.PasswordChecklist {
  @apply flex flex-wrap gap-x-6 text-primaryA;
}

.formError {
  @apply flex flex-row items-center h-8 text-negative text-sm;
}

.formInfo {
  @apply flex flex-row items-center h-8 text-gray-500 text-sm list-inside;
}

.formSuccess {
  @apply h-8 text-positive flex flex-row items-center text-sm;
}

.symbolError {
  @apply w-4 text-negative mr-1;
}
[dir='rtl'] .symbolError {
  @apply ml-1;
  margin-right: unset;
}

.symbolInfo {
  @apply w-4 text-gray-500 pl-1 mr-1;
  font-size: large;
}
[dir='rtl'] .symbolInfo {
  @apply ml-1;
  margin-right: unset;
}

.symbolSuccess {
  @apply bg-white mr-1;
}
[dir='rtl'] .symbolSuccess {
  @apply ml-1;
  margin-right: unset;
}
