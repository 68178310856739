.container {
  @apply flex flex-col justify-start items-center w-full relative pb-4 px-4 lg:px-28 text-center;
  max-width: 43.75rem;
}

.title {
  @apply font-gilda font-normal text-4xl m-0;
  font-size: 2rem;
}

.detail {
  @apply mt-4 font-inter font-normal text-base text-black;
  line-height: 1.5rem;
}
