.container {
  @apply flex flex-1 flex-row border-solid border-gray-300;

  @screen md {
    border-top-width: 1px;
  }
}

.column {
  @apply flex flex-col items-center p-4 lg:w-1/2 bg-primaryB;
}

.title {
  @apply uppercase font-inter font-semibold text-sm text-primaryA flex mb-1.5 leading-5;
}

.text {
  @apply text-sm font-inter font-normal text-center leading-5 text-gray-500;
}

.link {
  @apply underline cursor-pointer;
}

.carouselItem {
  @apply flex flex-col justify-start items-center min-w-full w-full;
  padding: 1rem;
}
