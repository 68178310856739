.formContainer {
  @apply w-full relative mb-3;
}

.formContent {
  @apply w-full  relative;
}

.title {
  @apply text-base font-medium;
  margin: 0 0 0.625rem 0;
}

.formAction {
  margin: 0.625rem 0 1.25rem;
}
.formAction button {
  @apply w-full text-sm ml-auto font-medium;
  height: 3rem;
}

.errorInput {
  @apply border border-solid border-negative;
}
.fadeIn {
  opacity: 0.3;
  transition: opacity 0.3s;
}
