.backdrop {
  @apply hidden md:block w-full h-full fixed inset-0 opacity-0 z-40;
  background-color: rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.3s linear forwards;
}

.container {
  @apply flex flex-col justify-between items-center w-screen fixed top-0 right-0  font-inter bg-primaryB opacity-100 z-50 invisible;
  transform: translateX(100%);
  transition: all 0.3s;

  @screen md {
    max-width: 33.25rem;
  }
}

.container.open {
  @apply visible;
  transform: translateX(0%);
}
.container.altAnim {
  @apply -right-full;
  transform: none;
}
.container.open.altAnim {
  @apply right-0;
  transform: none;
}

.close {
  @apply absolute top-0.5 right-0 bg-primaryB cursor-pointer;
  padding: 0.875rem 1.375rem;
  z-index: 51;

  @screen md {
    top: 0.8125rem;
  }
}
.cross {
  @apply h-5 w-5;
  stroke: #000;
}

.content {
  @apply flex flex-col w-full h-full relative px-0 md:px-11;
}
.hidden {
  @apply opacity-30;
}

.link {
  @apply flex flex-row justify-center md:justify-start items-center w-full relative pb-3.5 py-3 md:pt-8 font-medium text-xl text-primaryA border-b border-grey300;
  height: 3.4375rem;
  @screen md {
    @apply px-4 py-3.5;
    height: 4.6875rem;
  }
}
.link span {
  @apply inline-block ml-2 py-1.5 px-2.5 text-xs text-primaryB bg-primaryA rounded-full;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
[dir='rtl'] .link span {
  @apply mr-2;
  margin-left: unset;
}
.link:hover {
  @apply text-primaryA;
}

.list {
  @apply flex flex-col justify-start items-center w-full px-4 overflow-y-auto;
  height: calc(100% - 10.375rem);
  -ms-overflow-style: none;
  scrollbar-width: none;

  @screen md {
    height: calc(100% - 11.75rem);
  }
}
.list::-webkit-scrollbar {
  display: none;
}
.paypal .list {
  height: calc(100% - 13.125rem);
  @screen md {
    height: calc(100% - 14.25rem);
  }
}

.order {
  @apply flex flex-col justify-center items-center w-full relative px-7 md:px-11 py-8 bg-grey50 border border-grey300;
  height: 7rem;
}
.paypal .order {
  height: 9.75rem;
}

.cta {
  @apply w-full;
  padding: 1.5rem 1.25rem;
}

.paypalDisclaimer {
  @apply mt-2.5;
  padding-left: 0.7rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
