.container {
  @apply mt-2.5;
}

.title {
  @apply text-sm font-inter;
}

.selectors {
  @apply flex w-full justify-between mt-5;
  @screen xl {
    @apply justify-start;
  }
}

.small {
  width: 6.625rem;
  @screen xl {
    @apply mr-3;
    width: 5.875rem;
  }
}
[dir='rtl'] .small {
  @screen xl {
    @apply ml-3;
    margin-right: unset;
  }
}

.long {
  margin-left: 0.625rem;
  @screen xl {
    @apply ml-3;
  }
  width: 100%;
}
[dir='rtl'] .long {
  margin-left: unset;
  margin-right: 0.625rem;
}
.long > div:first-child > select {
  @apply lowercase;
}

.left {
  @apply flex w-full;
  margin-right: 0.75rem;
  @screen xl {
    @apply px-0;
    width: 48%;
  }
}

.right {
  width: 33%;
  @screen xl {
    margin-left: 0.75rem;
    width: 48%;
  }
}
[dir='rtl'] .right {
  @screen xl {
    margin-left: unset;
    margin-right: 0.75rem;
  }
}
