.formContainer {
  @apply w-full relative mb-3;
}

.formContent {
  @apply w-full  relative;
  margin-top: 1.1875rem;
}

.title {
  @apply text-xl font-medium;
}

.info {
  @apply w-full mt-5 text-sm font-normal text-primaryA;
  line-height: 1.375rem;
}

.formComment button {
  @apply text-grey600 w-full underline whitespace-nowrap;
}

.formAction {
  @apply w-full flex justify-between mt-2.5 mb-5 mx-0;
}

.formAction button {
  @apply h-12 w-1/2 text-sm whitespace-nowrap;

  @screen xl {
    @apply text-sm font-medium;
    width: 65%;
  }
}

.formItem:focus {
  @apply border border-solid border-primaryA;
}

.input {
  @apply flex w-full;
  @screen xl {
    @apply pt-0;
    width: 90%;
  }
}

.errorInput:hover.errorInput,
.errorInput {
  @apply border border-solid border-negative !important;
}

.container {
  @apply mt-5;
}

.selectors {
  @apply flex w-full justify-between mt-5;
  @screen xl {
    @apply w-1/2 justify-start items-start pr-3;
  }
}

.selectors > div:first-child {
  @apply mt-0;
}

.selectors > div:last-child > div {
  @apply pt-0;
}

.small {
  @apply mr-2.5;
  width: 40%;
  @screen xl {
    @apply mr-6;
    min-width: 4 rem;
  }
}
[dir='rtl'] .small {
  @apply ml-2.5;
  margin-right: unset;
  @screen xl {
    @apply ml-6;
    margin-right: unset;
  }
}
.long {
  @apply w-full;
}

.phoneInput {
  @apply h-12 w-full font-inter rounded-none !important;
  font-size: 1rem !important;
  @screen md {
    font-size: 0.875rem !important;
    width: calc(50% - 0.75rem) !important;
  }
}

.phoneInput:hover {
  @apply border border-grey300 !important;
}

.phoneInput:focus {
  @apply border border-solid border-primaryA !important;
  box-shadow: none !important;
}

.phoneInputContainer {
  @apply relative mb-2.5;
}
.phoneInputContainer > div {
  @apply font-inter;
  font-size: 0.875rem !important;
}

.alertMessage {
  @apply mb-2 h-auto;
  font-size: 0.75rem;
  margin-top: -0.5rem;
}

.phoneInputButton {
  @apply relative;
}

.phoneInputButton > div:first-child {
  margin-top: 0.1rem;
}

.phoneInputContainer > div:first-child {
  @apply px-2;
  top: -0.5rem;
  left: 0.625rem;
}
.phoneInputDropdown {
  margin-top: -2px !important;
  margin-left: 0px !important;
  border: solid 1px #ccc !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
