.formContainer {
  @apply w-full relative mb-5;
}

.formContent {
  @apply w-full relative mt-2.5 xl:mt-5;
}

.title {
  @apply text-base font-inter font-medium;
}
.title > span {
  @apply ml-1 font-normal;
  color: #999999;
}
[dir='rtl'] .title > span {
  @apply mr-1;
  margin-left: unset;
}

.info {
  @apply flex items-center w-full  mt-2.5 xl:mt-5 text-sm font-normal;
  margin-top: 0.625rem !important;
  line-height: 1.375rem;
  color: #121212;
}

.formComment button {
  @apply text-grey600 w-full underline whitespace-nowrap;
}

.formAction {
  @apply w-full flex justify-between mx-0 mt-2.5 mb-5;
}

.formAction button {
  @apply h-12 w-1/2 text-sm whitespace-nowrap;

  @screen md {
    @apply text-sm font-medium;
    width: 65%;
  }
}

.formItem:focus {
  @apply border border-solid border-primaryA;
}

.input {
  @apply flex w-full;
  @screen md {
    width: 90%;
  }
}

.errorInput {
  @apply border border-solid border-negative;
}

.inlineInputs {
  @apply w-full flex flex-col md:flex-row justify-between items-center;
}

.checkBox {
  @apply w-full flex flex-col justify-between;
  @screen md {
    @apply w-1/2 flex-row;
  }
}

.checkBoxItem {
  width: fit-content;
  @screen xl {
    width: auto;
  }
}

.checkBox label {
  @apply text-sm font-normal text-primaryA;
}

.selector {
  @apply w-full mr-0;
  @screen md {
    @apply mb-0;
    margin-left: 2%;
  }
}
[dir='rtl'] .selector {
  @screen md {
    margin-left: unset;
    margin-right: 2%;
  }
}

.incrementButton {
  @apply text-sm text-primaryA font-normal border-none normal-case;
  background-color: #f3f3f3;
}

.incrementButton:hover {
  @apply text-sm text-primaryA font-normal border-none;
  background-color: #e3e3e3;
}

.incrementLine {
  @apply flex justify-between;
  @screen md {
    @apply justify-start;
  }
}

.incrementLine .incrementButton:first-child {
  @apply mr-3 mb-5;
}
[dir='rtl'] .incrementLine .incrementButton:first-child {
  @apply ml-3;
  margin-right: unset;
}

.separator {
  @apply flex items-center text-2xl text-center;
  color: #b89336;
  margin-right: 0.375rem;
}

.gift {
  margin-right: 0.375rem;
}
