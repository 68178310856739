.title {
  @apply text-sm font-inter;
}

.content {
  @apply flex justify-around items-center h-12 p-1 border border-grey300 rounded-none;
  margin: 0.3125rem 0 0.625rem 0;
  font-size: 1.2rem;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  @screen md {
    width: 48%;
  }
}
.content:focus {
  @apply border border-solid border-primaryA;
}

.inputBox {
  @apply flex w-full h-full justify-center items-center relative px-2 text-sm;
}
.inputBox:not(:last-child, :first-child) {
  @apply border-r border-l border-grey300;
}

.input {
  @apply w-full h-full absolute appearance-none cursor-pointer z-0;
}
.input:checked {
  @apply bg-primaryA rounded-none;
}
.label {
  @apply cursor-pointer z-10;
}
.input:checked + .label {
  @apply text-primaryB;
}
