.container {
  @apply flex w-full h-full flex-col overflow-y-auto;
  @screen md {
    width: 57%;
  }
}

.header {
  @apply pr-8 py-4  bg-grey50 pl-4;

  @screen md {
    padding-left: 3.625rem;
  }
}
.title {
  @apply font-gilda;
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  @screen md {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.text {
  @apply text-base flex;
  line-height: 1.5rem;
}

.link {
  @apply ml-1 underline cursor-pointer;
}
[dir='rtl'] .link {
  @apply mr-1;
  margin-left: unset;
}

.modalContent {
  @apply mt-5 mx-4;

  @screen md {
    margin-inline: 3.625rem;
  }
}

.formContent {
  @screen md {
    @apply mb-5;
  }
}

.modalRow {
  @apply flex flex-1 flex-col justify-center items-center;
  @screen md {
    flex-direction: row;
    align-items: flex-start;
  }
}

.login {
  @apply w-full;
}

.resetSuccessInfo {
  @apply font-inter font-normal text-sm whitespace-pre-line;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.resetTitle {
  @apply relative pb-2 text-center;
}
.resetTitle strong {
  @apply text-primaryA text-base uppercase md:text-sm;
}

.ctaBackConnection {
  @apply m-0 w-full mb-10;
}
