.container {
  @apply flex justify-around items-center text-sm h-12 md:mr-6 p-1 border border-grey300 rounded-none;
  margin: 0.3125rem 0 0.625rem 0;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
}
[dir='rtl'] .container {
  @apply flex-row-reverse md:ml-6;
  @screen md {
    margin-right: unset;
  }
}

.formItem {
  @apply w-full h-full flex flex-col relative justify-center 2xl:justify-between items-start;

  @screen md {
    @apply flex-row justify-start items-center w-full;
  }
}
.formLabel {
  @apply absolute  text-sm bg-primaryB px-2;
  transform: translate(0, -140%);
}

.arrowDown {
  @apply absolute right-0  mr-1 flex items-center;
  transform: rotate(90deg);
}
[dir='rtl'] .arrowDown {
  @apply ml-1 left-0;
  margin-right: unset;
  right: unset;
}

.select {
  @apply w-full h-full z-10  bg-transparent cursor-pointer;
  padding-left: 0.4375rem;
}
