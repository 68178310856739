.root {
  @apply flex md:mr-3;
}
[dir='rtl'] .root {
  @apply md:ml-3;
  @screen md {
    margin-right: unset;
  }
}

.searchBarMobileIcon {
  @apply flex items-center;
  height: 1.5rem;
}
