@layer base {
  :root {
    --selection: var(--cyan);
    --backdrop: rgb(85, 85, 85, 0.65);

    --hover: rgba(0, 0, 0, 0.075);
    --hover-1: rgba(0, 0, 0, 0.15);
    --hover-2: rgba(0, 0, 0, 0.25);

    --primaryB: #ffffff;
    --primaryA: #1f1f1f;
    --grey50: #f6f6f6;
    --grey100: #eeeeee;
    --grey200: #e2e2e2;
    --grey300: #cbcbcb;
    --grey400: #afafaf;
    --grey500: #757575;
    --grey600: #545454;
    --positive: #23a942;
    --negative: #e3262f;
    --lime50: #e6f2ed;
    --red50: #ffefed;
    --promotion: #9c1027;
    --greenable: #38542f;
    --charitable: #b89336;
    --lowstock: #a15f23;
    --cream-dark: #ad8a5f33;
    --olive: #81845d4d;
    --loyalty: #b15a3b;
    --sales: #eb8424;
  }

  /* fonts */
  @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,500;0,600;1,400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap');

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    height: 100%;
    box-sizing: border-box;
    touch-action: manipulation;
    text-underline-offset: 0.1875rem;
    font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px !important;
  }

  html,
  body {
    @apply font-inter;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primaryB);
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    @apply text-sm md:text-xs;
    color: var(--primaryA);
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  b,
  strong,
  .bold {
    font-weight: 500;
  }

  button,
  input[type='button'],
  input[type='submit'] {
    @apply cursor-pointer;
    -webkit-tap-highlight-color: transparent;
  }

  input {
    @apply text-sm md:text-xs;
  }

  h2 {
    @apply text-2xl font-medium font-lora;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }

  .zoomOnHover {
    transform: scale(1);
    transition: transform 1s ease-in-out;
  }

  .zoomOnHover:hover {
    transform: scale(1.03);
  }

  .special-label {
    z-index: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}
