.container {
  @apply flex flex-col p-0 items-center;
}

.slash {
  @apply hidden;
}

.link {
  @apply cursor-pointer;
  margin-bottom: 1.375rem;
}

@screen md {
  .slash {
    @apply text-gray-300 text-xl ml-5 mr-5 p-0 flex;
    cursor: default;
  }
  .container {
    @apply flex flex-row;
  }

  .link {
    @apply mb-0 flex items-center text-sm md:text-xs lg:text-sm;
  }
}
