.root {
  @apply relative bg-primaryB mx-auto  transition-colors duration-150;
  max-width: 153.75rem;
}

.logo {
  @apply h-10;
  width: 8.75rem;

  @screen xl {
    @apply h-auto;
    width: 14.375rem;
  }
}

.header {
  @apply xl:h-auto sticky top-0 right-0 left-0 bg-primaryB z-20;
}

.container {
  @apply relative py-4 xl:py-6 px-2.5 flex flex-nowrap items-center justify-center;
}

.headerCenter {
  @apply flex flex-col justify-center items-center;
}

.catchPhrase {
  @apply hidden xl:block font-lora text-primaryA text-xxs;
}

.separator {
  @apply sticky left-0 right-0 bg-grey300 z-20;
  min-width: 100%;
  max-width: 100%;
  height: 0.0625rem;
  top: 4.5rem;

  @screen xl {
    top: 5.625rem;
  }
}

.bottom {
  @apply flex flex-col items-center w-full mx-auto py-3 md:py-8 border-t border-primaryA text-grey600;
}

.legals {
  @apply text-sm;
}

.copyright {
  @apply text-center uppercase;
}

@screen md {
  .bottom {
    @apply flex flex-row justify-between;
    padding: 0.625rem 2.813rem;
  }

  .legals {
    @apply flex;
  }
  .copyright {
    @apply flex my-0 text-sm;
    min-width: 8.438rem;
  }
}
