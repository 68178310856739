.formItem {
  @apply flex flex-col relative justify-center 2xl:justify-between items-start;

  padding: 0.625rem 0;
  @screen md {
    @apply flex-row justify-start items-center w-full;
  }
}

.darkTheme {
  @apply bg-transparent;
}

.formLabel {
  @apply absolute mx-1  text-sm bg-primaryB px-2;
  transition: transform 0.3s ease-in-out;
}

.formLabel.darkTheme {
  @apply bg-primaryA;
}

.formInput {
  @apply w-full outline-none px-2;
  font-size: 1.2rem;
  height: 3rem;
  letter-spacing: 0 !important;
  border: 0.0625rem solid #ccc;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0;

  @screen md {
    font-size: 0.875rem;
  }
}

.formInput.isPassword {
  @apply pl-2 pr-10;
}

.formInput:focus {
  @apply border border-solid border-primaryA;
}

.formInput.darkTheme:focus {
  @apply border-primaryB;
}
.formInput.errorInput {
  @apply border border-solid border-negative;
}

.formLabel.formUp,
.formInput:focus + .formLabel {
  transform: translate(5%, -140%);
}

.eye {
  @apply absolute right-0 bottom-0 mr-3 flex items-center;
  -webkit-tap-highlight-color: transparent;
  top: 50%;
  transform: translateY(-50%);
}
[dir='rtl'] .eye {
  @apply ml-3;
  margin-right: unset;
}
.alertMessage {
  @apply mb-2 h-auto;
}
