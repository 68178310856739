.container {
  @apply bg-cover bg-no-repeat bg-right-top overflow-hidden;
  width: 46%;
}

.register {
  @apply sticky;
  @screen md {
    top: 4.5rem;
    height: calc(100vh - 4.5rem);
  }
  @screen xl {
    top: 5.6875rem;
    height: calc(100vh - 5.6875rem);
  }
}
