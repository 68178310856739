.container {
  @apply fixed right-0 top-1/2 bg-primaryB border border-solid border-primaryA z-50;
  transition: transform 0.4s ease;
}
.container.close {
  transform: translateX(100%);
}

.button {
  @apply flex items-center justify-center w-9 h-9 absolute bg-primaryA text-primaryB text-2xl  font-medium cursor-pointer;
  transform: translateX(-100%);
}

.form {
  @apply px-5 py-3;
}
.form ul li {
  @apply flex my-2.5;
}
.form ul li span {
  @apply mr-1.5;
}
[dir='rtl'] .form ul li span {
  @apply ml-1.5;
  margin-right: unset;
}
.form ul li select {
  @apply px-0.5 border border-primaryA border-solid;
  -webkit-appearance: auto;
}

.error {
  padding: 0.5625rem 0.5rem;
}
