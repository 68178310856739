.container {
  @apply w-full relative;
  padding: 0.625rem 0;

  @screen lg {
    @apply ml-0 py-1.5 mr-3;
    max-width: 18.75rem;
  }
}
[dir='rtl'] .container {
  @screen lg {
    @apply ml-3 mr-0;
  }
}

.content {
  @apply flex flex-row justify-start items-center relative;
}

.box {
  @apply bg-primaryB cursor-pointer;
  min-height: 1.25rem;
  max-height: 1.25rem;
  min-width: 1.25rem;
  max-width: 1.25rem;
  border: 0.0625rem solid var(--grey300);
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.box:checked {
  background: var(--grey600);
}

.title {
  @apply flex flex-row justify-start items-center w-full pl-2 pr-1 text-grey600 text-left leading-4 cursor-pointer select-none whitespace-nowrap;
  font-size: 1rem;

  @screen lg {
    font-size: 0.875rem;
  }
}

.container svg {
  @apply absolute left-0 mt-0.5 cursor-pointer;
  transform: scale(0) translateY(-5%);
  animation: scaleUp 0.5s ease-in-out forwards;
}

.container:not(.disabled):hover .title,
.container:not(.disabled):hover svg path {
  @screen md {
    color: var(--primaryA);
  }
}
.container:not(.disabled):hover .box {
  @screen md {
    border-color: var(--primaryA);
  }
}

.disabled .title,
.disabled .box,
.disabled svg {
  @apply text-grey400 cursor-default;
}

.total {
  @apply ml-1 font-inter font-normal normal-case bg-grey100 text-primaryA;
  padding: 0.15625rem 0.3125rem;
  font-size: 0.75rem;
  border-radius: 0.1875rem;
}
[dir='rtl'] .total {
  @apply mr-1;
  margin-left: unset;
}

@keyframes scaleUp {
  0% {
    transform: scale(0) translateY(-5%);
  }
  50% {
    transform: scale(1.25) translateY(-5%);
  }
  100% {
    transform: scale(1) translateY(-5%);
  }
}
