.alert {
  @apply font-inter h-12 p-6 flex items-center text-sm w-full;
}

.default {
  @apply bg-grey200 text-primaryA;
}

.error {
  @apply bg-red50 text-primaryA;
}

.formError {
  @apply h-8 p-0 text-negative;
}

.formInfo {
  @apply h-8 p-0 text-primaryA;
}

.infos,
.success {
  @apply bg-lime50 text-primaryA;
}
