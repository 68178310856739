.section {
  @apply w-full;

  @screen xl {
    width: 9.375rem;
  }
}

.toggleButtonWrapper {
  @apply relative;
}

.title {
  @apply flex justify-between items-center w-full pl-0 py-5 border-t border-solid text-primaryA;
  border-color: var(--grey200);
  font-size: 0.875rem;
  transition: padding 0.15s cubic-bezier(0.75, 0.02, 0.5, 1);
  /* Differ padding transition on collapsed to hide it */
  transition-delay: 0.3s;

  @screen md {
    font-size: 0.8125rem;
  }
  @screen xl {
    @apply border-b border-t-0 border-solid border-primaryA text-left text-xs uppercase transition-none;
    padding: 0.9375rem 0 0.5625rem;
  }
}

.parentItemExpanded .title {
  @apply pl-10;
  transition-delay: 0.1s;

  @screen xl {
    @apply pl-0;
  }
}

.plusIcon {
  @apply block relative;
  width: 0.6875rem;
  height: 0.6875rem;

  @screen xl {
    @apply hidden;
  }
}
.plusIcon::after,
.plusIcon::before {
  @apply absolute bg-primaryA;
  content: '';
  transition: transform 0.15s cubic-bezier(0.75, 0.02, 0.5, 1);
}
.plusIcon::before {
  @apply top-0 w-px h-full;
  left: 0.3125rem;
}
.plusIcon::after {
  @apply top-1/2 w-full h-px;
  top: 0.3125rem;
}

.toggleButton {
  @apply absolute top-0 w-full h-full cursor-pointer;

  @screen xl {
    @apply hidden;
  }
}

.expanded .plusIcon::after {
  transform: rotate(360deg);
}
.expanded .plusIcon::before {
  transform: rotate(90deg);
}

.seeAll {
  @screen xl {
    @apply hidden;
  }
}

.seeAlllink {
  @apply underline;
}

.items {
  @apply block max-h-0 h-auto p-0 overflow-hidden opacity-0;
  transition: all 0.3s cubic-bezier(0.75, 0.02, 0.5, 1) 0.1s,
    opacity 0.15s cubic-bezier(0.75, 0.02, 0.5, 1) 0.3s;

  @screen xl {
    @apply transition-none opacity-100;
    padding: 0.9375rem 0 0.625rem;
    max-height: inherit;
  }
}
.expanded .items {
  @apply opacity-100 pb-5;
  max-height: 100rem;

  @screen xl {
    @apply pb-2.5;
    max-height: inherit;
  }
}

.item {
  @apply text-primaryA;
  font-size: 0.875rem;
  line-height: 1.3125rem;

  @screen md {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  @screen xl {
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
}

.item:active {
  @apply bg-gray-200 md:bg-transparent;
}
.item a {
  @apply block ml-10 py-2;

  @screen xl {
    @apply ml-0 py-1;
  }
}
[dir='rtl'] .item a {
  @apply mr-10;
  margin-left: unset;
  @screen xl {
    @apply mr-0;
  }
}

.inlineItem {
  @apply inline-block text-center font-medium;
}
.inlineItem a {
  @apply sm:box-content;
  width: 1.5625rem;
}
